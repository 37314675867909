<template>
  <v-navigation-drawer
    permanent
    app
    :mini-variant.sync="mini"
    v-model="drawer"
    class="pt-13"
  >
    <!--    
    <v-list>

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Sandra Adams </v-list-item-title>
          <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>


    -->
    <v-list-item class="px-2">
      <v-list-item-avatar style="background: #ededed;" class="mr-6">
        <div style="display: block; width:60px; heihgt: 60px; font-weight: bold; font-size: .8em">LHM</div>
      </v-list-item-avatar>

      <v-list-item-title>LHM Parken</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item link href="#/">
        <v-list-item-icon>
          <v-icon>mdi-desktop-mac-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item link href="#/analysen">
        <v-list-item-icon>
          <v-icon>mdi-glasses</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Analysen</v-list-item-title>
      </v-list-item>
      <v-list-item link href="#/faq">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>FAQ</v-list-item-title>
      </v-list-item>
      <!--
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Shared with me</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Starred</v-list-item-title>
      </v-list-item>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: true,
    };
  },
};
</script>