<template>
  <v-app>
    <v-app-bar app height="50px">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://dl.dropboxusercontent.com/s/5xtpes3lp6jadyl/logo-analytics-black.svg"
          transition="scale-transition"
          width="180"
        />

        <!--
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />

    -->
      </div>
      <v-spacer></v-spacer>
      
      <!--
      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>1212
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      -->
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://dl.dropboxusercontent.com/s/6cphtgrxz45usla/logo-lhm-muenchen.png"
        transition="scale-transition"
        width="160"
      />
    </v-app-bar>


    <navigation-drawer />
    <v-main class="pt-16">
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import NavigationDrawer from "./components/NavigationDrawer";

export default {
  name: "App",

  data: () => ({
    //
  }),

  components: {
    NavigationDrawer,
  },
};
</script>
