<template>
  <div class="dashboard">


    <v-container>
      <v-row justify="center" align="center">
        <v-col>
          <h1>Dashboard</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
          eirmod tempor invidunt ut l</v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col
          ><v-card elevation="3"
            ><v-card-text> <div id="mapContainer"></div></v-card-text></v-card
        ></v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
        <v-col cols="4"
          ><v-card elevation="3"
            ><v-card-text
              ><div
                id="chartTest"
                style="width: 100%; height: 200px; background: #ededed"
              ></div></v-card-text></v-card
        ></v-col>
      </v-row>
    </v-container>
    <v-container justify="center">
      <quick-navigation />
    </v-container>
  </div>
</template>

<script>
//import "leaflet/dist/leaflet.css";
//import L from "leaflet";
import QuickNavigation from "../components/QuickNavigation";
import L from "leaflet";

export default {
  name: "Home",
  data() {
    return {
      center: [49.1193089, 6.1757156],
    };
  },
  methods: {
    setupLeafletMap: function () {
      const mapDiv = L.map("mapContainer").setView(this.center, 10);
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}?access_token={accessToken}",
        {
          //ORG: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          attribution:
            'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: "mapbox/dark-v10",
          accessToken:
            "pk.eyJ1IjoiZW1lcnNvbmlrIiwiYSI6ImNrd3l4ZXEzMzBjaDcyeG9iajhjd2p5ODAifQ.Zu0OSqkWA_D3PilOminNzA",
        }
      ).addTo(mapDiv);
    },
  },
  mounted() {
    this.setupLeafletMap();
  },
  components: {
    //HelloWorld,
    QuickNavigation,
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 70vh;
}
</style>